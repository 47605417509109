import React from 'react';

import NeonPage from 'portal-core-components/lib/components/NeonPage';
import NeonRouter from 'portal-core-components/lib/components/NeonRouter';

import HomePage from './HomePage';

export default function App(): JSX.Element {
  return (
    <NeonRouter disableRedirect cleanPath={false}>
      <NeonPage>
        <HomePage />
      </NeonPage>
    </NeonRouter>
  );
}
