/* global document window */
import React, {
  useRef,
  SyntheticEvent,
} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';

import SiteMap from 'portal-core-components/lib/components/SiteMap';
import Theme from 'portal-core-components/lib/components/Theme';

import AnalyticsService from 'portal-core-components/lib/service/AnalyticsService';
import RouteService from 'portal-core-components/lib/service/RouteService';

import BannerBackgroundImage from './images/OAES-tower-2k.jpg';
import NavCardImageSledDogs from './images/sled-dogs.jpg';
import NavCardImageLENOTowerBirdsEye from './images/LENO-tower-birds-eye.jpg';
import NavCardImageAOPOSBS from './images/aop-osbs.jpg';
import NavCardImageBeetleSamples from './images/beetle-samples.jpg';
import NavCardImageKONZPrescribedBurn from './images/KONZ-prescribed-burn.jpg';
import NavCardImageLakeBuoy from './images/lake-buoy.jpg';

const SPACING = 4;
const useStyles = makeStyles((theme) => ({
  banner: {
    position: 'relative',
    backgroundImage: `url(${BannerBackgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundSize: 'contain',
    backgroundPositionX: 'center',
    width: `calc(100% + ${theme.spacing(16)}px)`,
    height: '260px',
    margin: theme.spacing(-4.25, 0, 2.5, -8),
    gridGap: theme.spacing(SPACING),
    padding: theme.spacing(4, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    // keep background image filling container between these sizes
    [theme.breakpoints.between(900, 960)]: {
      backgroundSize: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% + ${theme.spacing(12)}px)`,
      height: '250px',
      margin: theme.spacing(-1.5, 0, 2.5, -5.5),
      backgroundSize: 'auto',
      top: '-13px',
      left: '-4px',
    },
  },
  bannerTitle: {
    fontWeight: 500,
    textShadow: '0px 0px 16px white, 0px 0px 16px white, 0px 0px 16px white',
    padding: theme.spacing(0.25, 2),
    borderRadius: theme.spacing(5),
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    boxShadow: '0px 0px 16px 16px rgba(255, 255, 255, 0.6)',
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    // prevent search input from being pushed down out of container
    [theme.breakpoints.between(900, 960)]: {
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  topSubtitle: {
    width: '100%',
    textAlign: 'center',
    fontWeight: 400,
    color: theme.palette.grey[700],
    fontSize: '1.9em',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5em',
    },
  },
  topSubtitleSpan: {
    display: 'inline',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (min-width:770px) and (max-width:1280px)']: {
      display: 'block',
    },
  },
  searchForm: {
    width: '100%',
    maxWidth: '1000px',
  },
  searchPaper: {
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'baseline',
    width: '100%',
    padding: theme.spacing(2, 3, 1.5, 3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 2),
    },
  },
  searchLabel: {
    flexGrow: 0,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
      fontSize: '1.7em',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  searchInput: {
    flex: 1,
    color: theme.palette.grey[500],
    fontSize: '1.5em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3em',
    },
  },
  searchSubmit: {
    padding: '6px',
    marginLeft: theme.spacing(2),
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.75),
      marginLeft: theme.spacing(1),
    },
  },
  notificationBannerSection: {
    marginBottom: '20px',
  },
  notificationBanner: {
    padding: '10px',
    border: '2px solid #8FCAE7',
    borderLeft: '20px solid #8FCAE7',
  },
  notificationBannerText: {
    fontSize: '1.125rem',
    margin: '1em 0',
  },
  navCard: {
    padding: theme.spacing(3),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  navCardButton: {
    whiteSpace: 'nowrap',
    borderColor: Theme.palette.primary.main,
    '& span': {
      pointerEvents: 'none',
    },
  },
  divider: {
    margin: theme.spacing(SPACING, 0),
  },
}));

interface NavCardConfig {
  gtm: string;
  title: string;
  blurb: string;
  href: string;
  image: string;
  buttonTitle?: string;
}

export default function HomePage(): JSX.Element {
  const classes = useStyles(Theme);
  const searchRef = useRef(document.createElement('input'));

  const renderSearch = (): JSX.Element => {
    const handleSubmit = (event: SyntheticEvent): boolean => {
      event.preventDefault();
      const query = searchRef.current.value || '';
      if (!query.length) { return false; }
      AnalyticsService.gaPortalHomeSearchEvent(query);
      const newHref: string = RouteService.getDataProductExploreSearchPath(query);
      // Force redirect to a new render cycle to give GTM a chance to log the term
      window.setTimeout(() => { window.location.href = newHref; }, 0);
      return false;
    };
    return (
      <form onSubmit={handleSubmit} className={classes.searchForm} data-gtm="portal-home.search-form">
        <Paper className={classes.searchPaper}>
          <Typography variant="h4" className={classes.searchLabel}>
            Get Started…
          </Typography>
          <InputBase
            className={classes.searchInput}
            placeholder="Search data products by keywords, locations, states, years, etc..."
            inputProps={{
              ref: searchRef,
              color: Theme.palette.grey[500],
              'data-gtm': 'portal-home.search-input',
              'aria-label': 'search data products',
            }}
          />
          <IconButton
            type="submit"
            aria-label="search"
            className={classes.searchSubmit}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </form>
    );
  };

  const renderNavCard = (config: NavCardConfig): JSX.Element => {
    const {
      gtm = '',
      title = '',
      blurb = '',
      href = '',
      image = '',
      buttonTitle = 'Learn More',
    } = config;
    const titleGtm = title.toLowerCase().replace(/\W/g, ' ').trim().replace(/\s/g, '-');
    const dataGtm = 'portal-home.nav-card';
    const dataGtmValue = gtm || titleGtm;
    return (
      <Card style={{ height: '100%' }}>
        <CardActionArea
          component="a"
          href={href}
          style={{ padding: 0, height: `calc(100% - ${Theme.spacing(9)}px)` }}
          data-gtm={dataGtm}
          data-gtm-value={dataGtmValue}
        >
          <CardMedia
            style={{ height: Theme.spacing(18) }}
            image={image}
            title={title}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {blurb}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions style={{ justifyContent: 'flex-end', padding: Theme.spacing(2) }}>
          <Button
            component="a"
            variant="outlined"
            color="primary"
            className={classes.navCardButton}
            href={href}
            data-gtm={dataGtm}
            data-gtm-value={dataGtmValue}
          >
            {buttonTitle}
          </Button>
        </CardActions>
      </Card>
    );
  };

  const renderMap = (): JSX.Element => (
    <>
      <Typography gutterBottom variant="h4" style={{ textAlign: 'center' }}>
        Explore Data Products By Site
      </Typography>
      <SiteMap unusableVerticalSpace={200} view="split" />
    </>
  );

  return (
    <>
      <div className={classes.banner}>
        <Typography variant="h2" className={classes.bannerTitle}>
          Welcome to the NEON Data Portal
        </Typography>
        {renderSearch()}
      </div>
      <Typography variant="h4" className={classes.topSubtitle}>
        {/* eslint-disable react/jsx-one-expression-per-line */}
        <span className={classes.topSubtitleSpan}>
          The National Ecological Observatory Network provides&nbsp;
        </span>
        <span className={classes.topSubtitleSpan}>
          <b>open data</b> to understand changing ecosystems.
        </span>
        {/* eslint-enable react/jsx-one-expression-per-line */}
      </Typography>
      <Grid container spacing={SPACING} className={classes.notificationBannerSection}>
        <Grid item xs={12}>
          {/* eslint-disable react/jsx-one-expression-per-line */}
          <div className={classes.notificationBanner}>
            <Typography variant="h5" className={classes.notificationBannerText}>
              View our <a href={RouteService.getObservatoryStatusPath()}>Observatory Status</a> page
              to find out about current issues affecting NEON operations due to major impacts or
              events. This page is updated weekly.
            </Typography>
          </div>
          {/* eslint-enable react/jsx-one-expression-per-line */}
        </Grid>
      </Grid>
      <Grid container spacing={SPACING}>
        <Grid item xs={12} sm={6} lg={4}>
          {renderNavCard({
            title: 'Explore Data Products',
            blurb: 'Search and filter the catalog of all available and pending data.',
            href: RouteService.getDataProductExplorePath(),
            gtm: 'explore-data-products',
            image: NavCardImageSledDogs,
            buttonTitle: 'Explore',
          })}
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          {renderNavCard({
            title: 'Data Quality',
            blurb: 'NEON takes pride in delivering high-quality data and documentation.',
            href: RouteService.getDataQualityPath(),
            gtm: 'data-quality',
            image: NavCardImageLENOTowerBirdsEye,
          })}
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          {renderNavCard({
            title: 'Working with NEON Data',
            blurb: 'NEON provides open source utilities and tutorials to expedite analysis.',
            href: RouteService.getNeonUtilitiesDataStackRPath(),
            gtm: 'working-with-neon-data',
            image: NavCardImageAOPOSBS,
            buttonTitle: 'Get Started',
          })}
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          {renderNavCard({
            title: 'NEON Data API',
            blurb: 'Query and download NEON data programmatically or from the command line.',
            href: RouteService.getDataApiPath(),
            gtm: 'data-api',
            image: NavCardImageBeetleSamples,
            buttonTitle: 'Data API',
          })}
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          {renderNavCard({
            title: 'Data Portal News',
            blurb: 'Check out updates about newly published data and other content.',
            href: RouteService.getDataNotificationsPath(),
            gtm: 'news',
            image: NavCardImageKONZPrescribedBurn,
            buttonTitle: 'Latest News',
          })}
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          {renderNavCard({
            title: 'Have a Question?',
            blurb: 'New here? Wondering how to navigate NEON\'s many data products?',
            href: RouteService.getFaqPath(),
            gtm: 'faq',
            image: NavCardImageLakeBuoy,
            buttonTitle: 'NEON FAQ',
          })}
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      {renderMap()}
    </>
  );
}
